import React from 'react';
import {
  ComponentPreview,
  DontDo,
  DontDoItem,
  List,
  Link,
  PageHero,
  PageNavigation,
  PageNavigationLink,
  PageWithSubNav,
  Paragraph,
  PlatformExceptionList,
  PlatformExceptionItem,
  PlatformTable,
  PlatformTableRow,
  Section,
  SectionSubhead,
} from 'docComponents';
import { Spinner } from 'hudl-uniform-ui-components';
import spinnerPreviewData from '../../../data/previews/spinner.yml';
import pageHeroData from '../../../data/pages/components.yml';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Spinner"
      designCode
      subnav="components">
      <PageHero heroData={pageHeroData} tierTwo="Spinner" />

      <PageNavigation>
        <PageNavigationLink>Sizes</PageNavigationLink>
        <PageNavigationLink>States</PageNavigationLink>
        <PageNavigationLink>Mobile</PageNavigationLink>
        <PageNavigationLink>Usage</PageNavigationLink>
        <PageNavigationLink>Platform</PageNavigationLink>
      </PageNavigation>

      <Section title="Sizes">
        <Paragraph>
          Choosing the right size depends on where the loading content lives.
        </Paragraph>
        <ComponentPreview
          name="ButtonType"
          layout="split"
          previewData={spinnerPreviewData.sizes}>
          <Spinner />
        </ComponentPreview>
      </Section>

      <Section title="States">
        <Paragraph>
          We animate the spinner to tell the user things are happening and the
          content is on its way.
        </Paragraph>
        <ComponentPreview
          name="SpinnerState"
          layout="row"
          previewData={spinnerPreviewData.spinningStates}>
          <Spinner size="medium" />
        </ComponentPreview>
      </Section>

      <Section title="Mobile">
        <Paragraph>
          Spinners are closely aligned with <em>progress</em> on mobile. They’re
          generally used to represent activity, like pulling to refresh or
          synchronizing data. They might also be used to load a new experience
          or portion of the interface.
        </Paragraph>
        <PlatformExceptionList>
          <PlatformExceptionItem platform="apple">
            {' '}
            Use the spinner when checking for updates, or loading new content
            into a list. Don’t use it when reporting determinate progress, and
            never replace the network activity indicator in the status bar.
            Instead follow Apple’s{' '}
            <Link href="https://developer.apple.com/design/human-interface-guidelines/ios/controls/progress-indicators/">
              recommendations
            </Link>
            .
          </PlatformExceptionItem>
          <PlatformExceptionItem platform="android">
            Like Apple, when representing determinate progress, use progress
            indicators as outlined by{' '}
            <Link href="https://material.io/design/components/progress-indicators.html#usage">
              Material's guidelines
            </Link>
            .
          </PlatformExceptionItem>
        </PlatformExceptionList>
      </Section>

      <Section title="Usage">
        <SectionSubhead>Not for Progress</SectionSubhead>
        <Paragraph>
          The spinner should never be used to indicate progress. There’s nothing
          to track, we’re simply completing a quick call to our servers.
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="include any text regarding progress."
            img="spinner-textprogress-dont"
          />
          <DontDoItem
            type="do"
            text="allow the spinner to speak for itself."
            img="spinner-textprogress-do"
          />
        </DontDo>

        <SectionSubhead>Position</SectionSubhead>
        <Paragraph>
          Always place spinners in the center of the content’s container. Don’t
          hide it in the corner like the loading isn’t important.
        </Paragraph>
        <Paragraph>
          If the loading content lives outside of the viewport, center
          horizontally instead.
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="misplace a spinner where it may not be seen."
            img="spinner-position-dont"
          />
          <DontDoItem
            type="do"
            text="put it front and center to clearly indicate something’s happening."
            img="spinner-position-do"
          />
        </DontDo>

        <SectionSubhead>Limit per Page</SectionSubhead>
        <Paragraph>
          <strong>You get one spinner per page.</strong> Show too many at once
          and the user might get sick! If more than one piece of content is
          loading, stick to a large for the whole page.
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="give each container its own spinner."
            img="spinner-limit-dont"
          />
          <DontDoItem
            type="do"
            text="put one spinner in the center of the UI to indicate multiple pieces are loading."
            img="spinner-limit-do"
          />
        </DontDo>
      </Section>

      <Section title="Platform">
        <PlatformTable>
          <PlatformTableRow platform="web" />
          <PlatformTableRow platform="apple" />
          <PlatformTableRow platform="android" />
        </PlatformTable>
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
